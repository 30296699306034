import printError from './printError.js'

'use strict'

$('.packages__form-label').each(function (i, label) {
  let $label = $(label);
  $label.on('click', function () {
    $(this).parent().find('.packages__form-input').addClass('active').focus()
    $(this).addClass('active')
  })
});

$('.packages__form-input').on('invalid', function (e) {
  e.preventDefault();
  $(this).css('border-color', '#ff0000');
});

$('.packages__form-check').on('invalid', (e) => {
  e.preventDefault();
  $('.jq-checkbox__div').css('border-color', '#ff0000');
});
$('.packages__form-input').on('blur', function () {
  $(this).css('border-color', '')
});

$('.packages__form-check').on('change', function () {
  let $this = $(this);
  if ($this.is(':checked')) {
    $this.siblings('.jq-checkbox__div').css('border-color', '')
  } else {
    $this.siblings('.jq-checkbox__div').css('border-color', '#ff0000')
  }
});

if (!Modernizr.supports) {
  let formInput = $('.packages__form-input');
  formInput.css('border-color', 'rgba(1, 181, 227, 0)');

  formInput.on('invalid', function (e) {
    e.preventDefault();
    $(this).css('border-color', 'rgba(1, 181, 227, 0)');
    $(this).parent().find('.packages__form-label').css('color', '#ff0000');
  });
  formInput.on('valid', function (e) {
    $(this).parent().find('.packages__form-label').css('color', 'rgba(1, 181, 227, 0.6)');
    $(this).parent().find('.packages__form-label').css('color', 'rgba(1, 181, 227, 0)');
  });
  formInput.on('focus', function () {
    $(this).css('border-color', 'rgba(1, 181, 227, 0)');
    $(this).parent().find('.packages__form-label').css('color', 'rgba(1, 181, 227, 0.6)');
  });
  formInput.on('blur', function () {
    $(this).css('border-color', 'rgba(1, 181, 227, 0)');
  })
};

$('.form__input').on('focus', function () {
  let $this = $(this);
  $this.parent().find('.form__label').addClass('focus')
  $this.removeClass('error').parent().find('.form__item-error').text('')
}).on('blur', function () {
  printError($(this))
});

$('.form__input').each(function () {
  let $this = $(this)
  if ($this.val().length) {
    printError($this)
  }
});

$('#licence').on('change', function () {
  let $this = $(this)
  if ($this.is(':checked')) {
    $this.removeClass('error')
  } else {
    $this.addClass('error')
  }
});

function localizedUrl (url) {
  let fullUrl
  if (currentLanguage() === '') {
    fullUrl = url
  } else {
    fullUrl = '/' + currentLanguage() + url
  }

  return fullUrl
};

function currentLanguage () {
  return $('html').data('lang')
};

(function ($) {
  $.fn.serializeObject = function () {
    'use strict'

    let result = {}
    let extend = function extend (i, element) {
      let node = result[element.name]

      // If node with same name exists already, need to convert it to an array as it
      // is a multi-value field (i.e., checkboxes)

      if (typeof node !== 'undefined' && node !== null) {
        if ($.isArray(node)) {
          node.push(element.value)
        } else {
          result[element.name] = [node, element.value]
        }
      } else {
        result[element.name] = element.value
      }
    };

    $.each(this.serializeArray(), extend)
    return result
  }
})(jQuery)
