import './libs/owl.carousel.min.js'

$(document).ready(function () {
  $(window).resize(function() {
    if ($(window).width() < 1040) {
      $('#al-carousel').addClass('owl-drag owl-grab');
    }
    else {
      $('#al-carousel').removeClass('owl-drag owl-grab');
    }
  });

  $('#al-carousel').owlCarousel({
    loop: false,
    items:4,
    mouseDrag: true,
    touchDrag: true,
    responsiveClass: true,
    responsive: {
      0: {
        dotsEach: 1,
        items: 1,
        touchDrag: true,
        mouseDrag: true
      },
      700: {
        dotsEach: 2,
        items: 2,
        touchDrag: true,
        mouseDrag: true
      },
      1040: {
        touchDrag: true,
        mouseDrag: true
      },
      1050:{
        dotsEach: 3,
        items:3,
        touchDrag: false,
        mouseDrag: false

      },
      1600: {
        dotsEach: 4,
        items: 4,
        touchDrag: false,
        mouseDrag: false
      },
      2000: {
        dotsEach: 4,
        items: 4,
        touchDrag: false,
        mouseDrag: false
      }
    }
  })

  $('#before-slider').owlCarousel({
    loop: true,
    items: 1,
    margin: 10,
    nav: true,
    dots: true,
    navText: ['', '']
  })

  $('#reviews-slider').owlCarousel({
    loop: true,
    items: 1,
    margin: 0,
    nav: true,
    dots: false,
    navContainer: '#customNav',
    navText: ['', '']
  })

  var afterpartySlider = $('#afterparty-slider')
  afterpartySlider.addClass('owl-carousel').owlCarousel({
    loop: true,
    items: 1,
    margin: 0,
    nav: true,
    dots: true,
    navText: ['', ''],
    center: true
  })
})
