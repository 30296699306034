import createErrorElement from './createErrorElement.js'
import msg from './getMessages.js'
import rules from './validationRules.js'

export default function (item) {
  const regex = item.data('rule')
  const RULES = rules()
  const MSG = msg()

  if (!item.val().length) {
    if (item.attr('required') === 'required') {
      item.addClass('error')

      if (!item.parent().find('.form__item-error').length) {
        createErrorElement(item.parent())
      }
      item.parent().find('.form__item-error').text(MSG.error_empty)
    } else {
      item.parent().find('.form__label').removeClass('focus')
    }
  } else {
    item.parent().find('.form__label').addClass('focus')

    if (RULES[regex] !== undefined) {
      if (RULES[regex].test(item.val())) {
        item.removeClass('error')
      } else {
        item.addClass('error')
        if (MSG[item.attr('id')] !== undefined) {
          if (!item.parent().find('.form__item-error').length) {
            createErrorElement(item.parent())
          }
          item.parent().find('.form__item-error').text(MSG[item.attr('id')])
        }
      }
    }
  }
  $('.form__radio').on('change', function () {
    item.removeClass('error');
    item.parent().find('.form__item-error').remove()
  })
}
