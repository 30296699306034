import './libs/jquery.formstyler.min.js'

$('.packages__form-check').styler()

$('.packages__form-input').each(function () {
    var $this = $(this)

    var val = $this.val()

    if (val.length) {
        $this.addClass('active').next('.packages__form-label').addClass('active')
    }
}).on('focus', function () {
    $(this).addClass('active').next('.packages__form-label').addClass('active')
}).on('blur', function () {
    var $this = $(this)

    var val = $this.val()

    if (val.length) {
        $this.addClass('active').next('.packages__form-label').addClass('active')
    } else {
        $this.removeClass('active').next('.packages__form-label').removeClass('active')
    }
})
$('.packages__tab').on('click', function (e) {
    e.preventDefault()
    var $this = $(this)

    var tarif = $this.data('tarif')

    changePackage(tarif)
    $this.addClass('selected')
    $('.packages h2.primaryCaption').addClass('opened')

    if (window.innerWidth <= 1068) {
        $('html, body').animate({
            scrollTop: $('#packages-full').offset().top
        }, 300)
    }
})
$('.packages__full-close').on('click', function () {
    $('#packages-tabs').removeClass('small')
    $('.packages h2.primaryCaption').removeClass('opened')
    $('#packages-full').removeClass('active platinum party premium exhibitor')
    $('.packages__tab.selected, .packages__full.selected').removeClass('selected')
})
$('.packages__next').on('click', function () {
    var tarif = $(this).data('next')

    changePackage(tarif)
    $('.packages__tab-' + tarif).addClass('selected')
})

function changePackage(tarif) {
    $('#packages-tabs').addClass('small')
    $('.packages__tab.selected').removeClass('selected')
    $('#packages-full').removeClass('platinum party premium exhibitor').addClass('active').addClass(tarif)
    $('.packages__full').removeClass('selected')
    $('#' + tarif).addClass('selected')
}

$(".sponsorForm").on('submit', function(e) {
    e.preventDefault();

    $.ajax({
        url: $(this).attr('action'),
        type: "POST",
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify($(this).serializeObject()),
        context:this,
    }).done(function (res, status) {
        $(this).closest(".packages__form-wrap").addClass("hidden").closest(".packages__form").find(".packages__form-message").html(MESSAGES.thanks);
    }).fail(function (response) {
        $(this).closest(".packages__form-wrap").addClass("hidden").closest(".packages__form").find(".packages__form-message").html(MESSAGES.error);
    });
});
