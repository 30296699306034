import './css/style.scss'
import './../common/js/modernizr.js'
import * as $ from 'jquery'
import './../common/js/menu2.js'
import msg from './../common/js/getMessages.js'
import rules from './../common/js/validationRules.js'
import './../common/js/sliders.js'
import './../common/js/app.js'
import './../common/js/packages.js'
import './../common/js/modernizr-custom.js'
import './../common/js/three.js'
import './../common/js/wow.js'
import './../common/js/subscribe.js'
import './../common/js/jquery.cookie.js'
import './../common/js/lazysizes.min.js'

$('.cookies__close').on('click', function () {
  $('.cookies').hide()

  $.ajax({
    type: 'POST',
    url: '/cookie/accept_cookie'
  })
})

const RULES = rules();
const MSG = msg();

function printError (item) {
  var regex = item.data('rule')
  if (!item.val().length) {
    if (item.attr('required') === 'required') {
      item.addClass('error')

      if (!item.parent().find('.form__item-error').length) {
        createErrorElement(item.parent())
      }
      item.parent().find('.form__item-error').text(MSG.error_empty)
    } else {
      item.parent().find('.form__label').removeClass('focus')
    }
  } else {
    item.parent().find('.form__label').addClass('focus')

    if (RULES[regex] !== undefined) {
      if (RULES[regex].test(item.val())) {
        item.removeClass('error')
      } else {
        item.addClass('error')
        if (MSG[item.attr('id')] !== undefined) {
          if (!item.parent().find('.form__item-error').length) {
            createErrorElement(item.parent())
          }
          item.parent().find('.form__item-error').text(MSG[item.attr('id')])
        }
      }
    }
  }
}

$('.form__input').on('focus', function () {
  var $this = $(this)
  $this.parent().find('.form__label').addClass('focus')
}).on('blur', function () {
  printError($(this))
})

$('.form__input').each(function () {
  var $this = $(this)
  if ($this.val().length) {
    printError($this)
  }
})

$(".form__submit .button[type='submit']").on('click', function () {
  $('.form__input').each(function () {
    printError($(this))
  })
  if (!$('#licence').is(':checked')) {
    $('#licence').addClass('error')
  }
  if (!$('#shortRegister .error').length) {
    $('#shortRegister').submit()
  }
  return false
})

$(document).ready(function () {
  $('.form__input').on('focus', function () {
    var $this = $(this)
    $this.parent().find('.form__label').addClass('focus')
    $this.removeClass('error').parent().find('.form__item-error').text('')
  }).on('blur', function () {
    printError($(this))
  })

  $('.form__input').each(function () {
    var $this = $(this)
    if ($this.val().length) {
      printError($this)
    }
  })

  $('#licence').on('change', function () {
    var $this = $(this)
    if ($this.is(':checked')) {
      $this.removeClass('error')
    } else {
      $this.addClass('error')
    }
  })

  var shortForm = $('#shortRegister')

  shortForm.on('submit', function (e) {
    e.preventDefault()

    $('.form__input').each(function () {
      printError($(this))
    })

    if (!$('#licence').is(':checked')) {
      $('#licence').addClass('error')
    }
    if (!$('#shortRegister .error').length) {
      var welcomeName = $('#welcomeName').val()

      var welcomeSurname = $('#welcomeSurname').val()

      var welcomePhone = $('#welcomePhone').val()

      var welcomeEmail = $('#welcomeEmail').val()

      var data = {
        'firstName': welcomeName,
        'lastName': welcomeSurname,
        'phone': welcomePhone,
        'email': welcomeEmail
      }
      $.ajax(localizedUrl('/user/register'), {
        type: 'post',
        contentType: 'application/json',
        data: JSON.stringify(data)
      }).done(function (response, status) {
        $('#shortRegister__thanks').html(MSG.thanks)
        $('#shortRegister__msg').hide()
        shortForm.remove()
      }).fail(function (response) {
        if (response.responseJSON.errors !== undefined) {
          for (var i = 0; i < response.responseJSON.errors.length; i++) {
            var fieldName = response.responseJSON.errors[i].field

            var fieldError = response.responseJSON.errors[i].message

            var itemId

            switch (fieldName) {
              case 'firstName':
                itemId = '#welcomeName'
                break
              case 'lastName':
                itemId = '#welcomeSurname'
                break
              case 'phone':
                itemId = '#welcomePhone'
                break
              case 'email':
                itemId = '#welcomeEmail'
                break
            }

            if ($(itemId).length) {
              if (!$(itemId).parent().find('.form__item-error').length) {
                createErrorElement($(itemId).parent())
              }
              $(itemId).addClass('error').parent().find('.form__item-error').text(fieldError)
            }
          }
        } else {
          $('#shortRegister__msg').html(MSG.error)
        }
      })
    }
  })
})

function createErrorElement (appendToBlock) {
  $('<div></div>', {
    class: 'form__item-error',
  }).appendTo(appendToBlock)
}

function localizedUrl (url) {
  var fullUrl
  if (currentLanguage() === '') {
    fullUrl = url
  } else {
    fullUrl = '/' + currentLanguage() + url
  }

  return fullUrl
}

function currentLanguage () {
  return $('html').data('lang')
}

$.fn.serializeObject = function () {
  'use strict'

  var result = {}
  var extend = function extend (i, element) {
    var node = result[element.name]

    // If node with same name exists already, need to convert it to an array as it
    // is a multi-value field (i.e., checkboxes)

    if (typeof node !== 'undefined' && node !== null) {
      if ($.isArray(node)) {
        node.push(element.value)
      } else {
        result[element.name] = [node, element.value]
      }
    } else {
      result[element.name] = element.value
    }
  }

  $.each(this.serializeArray(), extend)
  return result
}

// function formatMoney (amount, decimalCount, decimal, thousands) {
//   var decimalCount = isNaN(decimalCount = Math.abs(decimalCount)) ? 2 : decimalCount

//   var decimal = decimal === undefined ? '.' : decimal

//   var thousands = thousands === undefined ? ' ' : thousands

//   var sign = amount < 0 ? '-' : ''

//   var i = String(parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)))

//   var j = (j = i.length) > 3 ? j % 3 : 0

//   return sign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '')
// }
