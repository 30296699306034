$(document).ready(function () {
    $(".subscribe .close, .packages__tab, .menu-trigger").click(function () {
        hideSubscribeForm()
    })
    $(".subscribe .small-text").click(function () {
        $(".subscribe").removeClass('small');
        $(".subscribe").addClass('active');
    })

    var lang = $('html').data('lang')
    var url = '/subscribe'
    if (lang) {
        url = '/' + lang + url
    }
    var form = $('#subscription_form')

    form.submit(function (event) {
        event.preventDefault()
        var formData = form.serializeObject()
        $.ajax({
            url: url,
            type: 'POST',
            data: JSON.stringify(formData),
            contentType: false,
            processData: false
        }).done(function (res) {
            /*hideSubscribeForm();*/
            $(".subscribe").addClass('good');
            setTimeout(function () {
                hideSubscribeForm();
            }, 4000);
        }).fail(function (res) {
            console.log(res);
            $('#apierror').append(res.responseJSON.error);
        })
    })

    function hideSubscribeForm() {
        $(".subscribe").removeClass('active');
        $(".subscribe").addClass('small');
    }

    $('.infocrypto .close').click(function () {
        $(".infocrypto").addClass('hidden');
    })
});

(function ($) {
    $(function () {

        // Проверим, есть ли запись в куках о посещении посетителя
        // Если запись есть - ничего не делаем
        if (!$.cookie('was')) {
            // Покажем всплывающее окно
            setTimeout(function () {
                $(".infocrypto").removeClass('hidden');
            }, 5000);
            
            setTimeout(function () {
                $('.subscribe.first').addClass('active');
                $('.subscribe').removeClass('first');
            }, 10000);
            
            var a = $("#tickets");
            var posa = a.position();
            $(window).scroll(function onScroll() {
                var windowpos = $(window).scrollTop();
                if (windowpos + 270 >= a.offset().top && windowpos <= a.offset().top + a.height() - 370) {
                    $('.subscribe.first').addClass('active');
                    $('.subscribe').removeClass('first');
                    $(window).off('scroll', onScroll);
                }
            });
        }
        
        else{
            setTimeout(function () {
                $('.subscribe').addClass('small');
            }, 10000);
        }

        // Запомним в куках, что посетитель к нам уже заходил
        $.cookie('was', true, {
            expires: 365,
            path: '/'
        });

    })
})(jQuery)
