$(document).ready(function () {
    $(".menu-trigger").click(function () {
        $(".menu-trigger,.top,body").toggleClass('active');
        $(".menu").toggle("0", function () {
            $(this).addClass('active');
            if ($(this).is(':visible')) $(this).css('display', 'flex');
        });
        $(".menu ul,.menu .smallForm").toggle("500", function () {
            $(this).addClass('active');
            if ($(this).is(':visible')) $(this).css('display', 'flex');
        });
        $(".top").removeClass('mform');
    })
    if (screen.width < 1290) {
        $('#perlin').remove();
    } else {

    }
});

$(".menu__item").click(function () {
    $(".menu,.menu ul,.menu .smallForm").css('display', 'none').removeClass('active');
    $('.menu-trigger,.top,body').removeClass('active');
});

var mywindow = $(window);
var mypos = mywindow.scrollTop();
var up = false;
var newscroll;

$(window).on("scroll", function () {
    if ($(window).scrollTop() > 620) $('.top').addClass('white');
    else $('.top').removeClass('white');
});